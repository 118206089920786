/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const fullurl = () => location.pathname + location.search

exports.onRouteUpdate = args => {
  // const { location } = args
  const { MathJax } = window
  if (MathJax !== undefined && MathJax.Hub) {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  let scripts = document.querySelectorAll('[data-inline-script]');
  scripts.forEach(function forEachScript(element) {
      const script = element.innerHTML;
      window.eval(script);
  });

  if (!location.hash) {
    ping({ type: 'page_view', url: fullurl() })
  }
}

exports.onClientEntry = () => {
  ping({ 
    type: 'load', 
    url: fullurl(),
    referer: document.referrer
  })

  setLinksClickHandler()
  setTimeout(() => ping({ type: 'ping', url: fullurl() }), 150000)
  // setTimeout(() => ping({ type: 'ping', url: location.href }), 300000)
  // setTimeout(() => ping({ type: 'ping', url: location.href }), 600000)
}

function ping(params) {
  const url = new URL(location.origin + "/hub/rx")
  url.search = new URLSearchParams({
    screenw: window.screen.availWidth,
    screenh: window.screen.availHeight,
    platform: window.navigator.platform,
    lang: navigator.language,
    ...params
  })
  fetch(url)
}

function setLinksClickHandler() {
  function cb(e) {
    if (e.target.tagName != 'A')
      return

    const pageurl = new URL(location.href)
    const linkurl = new URL(e.target.href)

    // if (linkurl.hash)
    //   return

    if (pageurl.origin !== linkurl.origin) {
      ping({
        type: 'link_click',
        url: e.target.href,
        referer: location.pathname
      })
      // if (gtag) 
      //   gtag('event', 'link_click', { url: e.target.href })
    }
  }

  if (document) {
    if (document.addEventListener) {
      document.addEventListener('click', cb, { passive: true })
    } else {
      document.attachEvent('click', cb)
    }
  }
}